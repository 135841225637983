<template>
  <p v-if="!optclients"><em>Loading...</em></p>
  <h4 id="tableLabel">优化服务器</h4>
  <table
    class="table table-striped table-condensed"
    aria-labelledby="tableLabel"
    v-if="optclients"
  >
    <thead>
      <tr>
        <th>服务器名称</th>
        <th>服务器类型</th>
        <th>优化次数</th>
        <th>成功次数</th>
        <th>失败次数</th>
        <th>当前状态</th>
        <th>客户</th>
        <th>用户</th>
        <th>连接Id</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="optclient of optclients" v-bind:key="optclient">
        <td>{{ optclient.optClientName }}</td>
        <td>{{ optclient.optClientType }}</td>
        <td>{{ optclient.youhuaCount }}</td>
        <td>{{ optclient.successCount }}</td>
        <td>{{ optclient.failCount }}</td>
        <td>{{ optclient.status == 0 ? "空闲" : "正在优化" }}</td>
        <td>{{ optclient.customer }}</td>
        <td>{{ optclient.user }}</td>
        <td>{{ optclient.connectionId }}</td>
      </tr>
    </tbody>
  </table>

  <p></p>
  <h4 id="tableLabel">优化用户</h4>
  <table
    class="table table-striped table-condensed"
    aria-labelledby="tableLabel"
    v-if="webclients"
  >
    <thead>
      <tr>
        <th>类型</th>
        <th>客户</th>
        <th>客户url</th>
        <th>用户</th>
        <th>当前状态</th>
        <th>优化服务器</th>
        <th>连接Id</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="webclient of webclients" v-bind:key="webclient">
        <td>{{ webclient.yhType }}</td>
        <td>{{ webclient.customer }}</td>
        <td>{{ webclient.url }}</td>
        <td>{{ webclient.user }}</td>
        <td>{{ webclient.status == 0 ? "排队..." : "正在优化" }}</td>
        <td>{{ webclient.optClientName }}</td>
        <td>{{ webclient.optClientConnectionId }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import axios from "axios";
export default {
  name: "OptClient",
  data() {
    return {
      optclients: [],
      webclients: [],
    };
  },
  methods: {
    getOptClient() {
      axios
        .get("/api/optclient")
        .then((response) => {
          this.optclients = response.data;
        })
        .catch(function (error) {
          console.error(error);
        });
      axios
        .get("/api/webclient")
        .then((response) => {
          this.webclients = response.data;
        })
        .catch(function (error) {
          console.error(error);
        });
    },
  },
  mounted() {
    this.getOptClient();
  },
};
</script>
