import { createWebHistory, createRouter } from "vue-router";
import OptClient from "@/components/OptClient.vue";

const routes = [
    {
        path: "/",
        name: "OptClient",
        component: OptClient,
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;